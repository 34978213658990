import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { Api } from 'app/services/api.service';

function getRedirectUrl(router: Router, state: RouterStateSnapshot) {
  const redirectURL = state.url === '/sign-out' ? '' : `redirectURL=${state.url}`;
  return router.parseUrl(`sign-in?${redirectURL}`);
}

export const AuthGuard: CanActivateFn | CanActivateChildFn = async (route, state) => {
  const api = inject(Api);
  const router = inject(Router);

  try {
    const { data, error } = await api.supabase.auth.getSession();

    if (error || !data.session) {
      return getRedirectUrl(router, state);
    }
  } catch (error) {
    return getRedirectUrl(router, state);
  }
};
